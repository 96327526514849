import React, {Component} from 'react';
import { connect } from "react-redux";
import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, UserPurgeNowControlPanelKey } from "../../redux/actions/controlPanelActions";
import configData from "../../config/config.json";
import Confirmation from '../../components/common/modal/Confirmation';
import { handleError } from '../../redux/actions/commonStuff';
import { LOADING_STATUES } from "../../components/constants/constants";

const componentName = UserPurgeNowControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

class UserPurgeNow extends Component {

  state = {
    purgeDate: "",
    loadingStatus: "",
    message: ""
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  showLoading = () => {
		if (this.state.loadingStatus === "") {
			this.setState({
				loadingStatus: LOADING_STATUES.LOADING
			})
			return true;
		}
		return false;
	}

  // Delete methods
  handleDeleteUser = () => {
    
    if (this.showLoading() === true) {
     
      axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/userPurgeNow", this.state, { withCredentials: true })
        .then(
          (response) => {
            this.setState({
              loadingStatus: LOADING_STATUES.SUCCESS
            });
          },
          (error) => {
            handleError(error, this.props.history);
          }
        );
    }
  }

	showDeleteConfirmation = (event) => {
		event.preventDefault();
		this.setState({
			showDeleteConfirmationDialog: true
		})
	}

	hideDeleteConfirmation = () => {
		this.setState({
			showDeleteConfirmationDialog: false
		})
	}
	// Delete methods - End

	componentDidMount() {
    this.props.setSelectedControlPanelType();

    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/userPurgeNow", { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState( {
          purgeDate: content.purgeDate,
        });
      },
      (error) => {
        console.log(error);
      }
    );
    
  }
  
  render() {
    const formProps = {
      componentName: componentName,
      pageHeading: "User Admin - Purge Now",
      onChange: this.handleChange,
      onSubmit: this.showDeleteConfirmation,
      successIndicator: this.state.loadingStatus === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.loadingStatus === LOADING_STATUES.LOADING,
      formGroupRows: [
        {
          formGroups: [{ id: 'purgeDate', label: 'Credit Card Users inactive since', fieldType: 'date', value: this.state.purgeDate }]
        }
      ]
    }

    return (
      <>
      <FormTemplate {...formProps} />
      <Confirmation show={this.state.showDeleteConfirmationDialog} cancelConfirmation={this.hideDeleteConfirmation} proceedConfirmation={this.handleDeleteUser} question={'Delete inactive credit card users?'} />
      </>
     )
  }
}

export default connect(null, matchDispatchToProps)(UserPurgeNow);