import React, {Component} from 'react';
import { Form, Button, Col, FormFile } from 'react-bootstrap'

import DateField from "./DateField";
import PlatField from "./PlatField";
import TextField from "./TextField";
import SelectField from "./SelectField";
import RadioGroup from "./RadioGroup";

import { searchIcon, listIcon } from "../../../img/font_awesome/font_awesome"

// A FieldRow may have one or more fields.
export default class FieldRow extends Component {
    generateRow = (formGroupRow) => {
        return (
            formGroupRow.formGroups.map((formGroup, index) => { // looping thru the formGroups within the row.
                let lgSize = formGroup.lgSize ? formGroup.lgSize : 'auto';
                let mdSize = formGroup.mdSize ? formGroup.mdSize : 'auto';
                let smSize = formGroup.smSize ? formGroup.smSize : 'auto';
                let xsSize = formGroup.xsSize ? formGroup.xsSize : 'auto';
                return (
                    <Col key={formGroup.id} lg={lgSize} md={mdSize} sm={smSize} xs={xsSize}  >
                        <Form.Group key={formGroup.id} controlId={formGroup.id} >
                            { /* Text Field */
                                (formGroup.fieldType === 'text' || formGroup.fieldType === 'password' || formGroup.fieldType === 'zip' || formGroup.fieldType === 'tel' || formGroup.fieldType === 'tel_international' || formGroup.fieldType === 'email' || formGroup.fieldType === 'number') && formGroup.hideField !== true &&
                                <>
                                    <TextField formGroup={formGroup} onChange={this.props.onChange} disabled={this.props.disabled || formGroup.disabled} />
                                    <div style={{color: "red", fontWeight: "bold"}}>{formGroup.error}</div>
                                </>
                            }
                            { /* Plat Field */
                                (formGroup.fieldType === 'plat') && formGroup.hideField !== true &&
                                <>
                                    <PlatField formGroup={formGroup} onChange={this.props.onChange} disabled={this.props.disabled || formGroup.disabled} />
                                    <div style={{color: "red", fontWeight: "bold"}}>{formGroup.error}</div>
                                </>
                            }
                            { /* Date Field */
                                formGroup.fieldType === 'date' && formGroup.hideField !== true &&
                                <>
                                    <DateField formGroup={formGroup}  onChange={this.props.onChange} disabled={this.props.disabled} />
                                    <div style={{color: "red", fontWeight: "bold"}}>{formGroup.error}</div>
                                </>
                            }
                            { /* Dropdown */
                                formGroup.fieldType === 'select' && formGroup.hideField !== true &&
                                <>
                                    <SelectField formGroup={formGroup} disabled={this.props.disabled} onChange={this.props.onChange}/> 
                                    <div style={{color: "red", fontWeight: "bold"}}>{formGroup.error}</div>
                                </>
                            }
                            { /* Button */
                                formGroup.fieldType === 'lookup' && formGroup.hideField !== true &&
                                <>
                                    <div>&nbsp;</div>
                                    <Button variant="primary" onClick={formGroup.onClick}>{searchIcon}</Button>
                                </>
                               
                            }
                            { /* Button */
                                formGroup.fieldType === 'lookupWithLabel' && formGroup.hideField !== true &&
                                <>
                                    <div>&nbsp;</div>
                                    <Button variant="secondary" style={formGroup.style} onClick={formGroup.onClick}>{listIcon} {formGroup.label}</Button>
                                </>
                               
                            }
                            { /* Button */
                                formGroup.fieldType === 'reset' && formGroup.hideField !== true &&
                                <>
                                    <div>&nbsp;</div>
                                    <Button variant="secondary" onClick={formGroup.onClick}>Reset</Button>
                                </>
                               
                            }
                            { /* File */
                                formGroup.fieldType === 'file' && formGroup.hideField !== true &&
                                <div style={{marginLeft: 3}}>
                                    <div style={{fontWeight: 'bold'}}>{formGroup.label}:</div>
                                    <FormFile onChange={formGroup.onChange} key={formGroup.key} ></FormFile>
                                    <div style={{color: "red", fontWeight: "bold"}}>{formGroup.error}</div>
                                </div>
                            }
                            { /* Label */
                                formGroup.fieldType === 'label' && formGroup.hideField !== true &&
                                <span>{formGroup.value}</span>
                            }
                            { /* Label */
                                formGroup.fieldType === 'textlike' && formGroup.hideField !== true &&
                                <>
                                    <div>&nbsp;</div>
                                    <div style={{marginTop: 5}}>{formGroup.value}</div>
                                   
                                    
                                </>
                            }
                            { /* Radio */                            
                                formGroup.fieldType === 'radio' && formGroup.hideField !== true &&
                                <>
                                    <RadioGroup formGroup={formGroup} disabled={this.props.disabled} onChange={this.props.onChange}/> 
                                    <div style={{color: "red", fontWeight: "bold"}}>{formGroup.error}</div>
                                </>
                            }

                            { /* Checkbox */                            
                                formGroup.fieldType === 'checkbox' && formGroup.hideField !== true && formGroup.checked === true &&
                                <>
                                    <Form.Check id={formGroup.id} style={{fontWeight: 'bold'}} type='checkbox' checked={true} onChange={formGroup.onChange} label={formGroup.label} />
                                    {
                                        formGroup.error && formGroup.error != '' && 
                                        <div style={{color: "red", fontWeight: "bold"}}>&nbsp;&nbsp;&nbsp;&nbsp;{formGroup.error}</div>
                                    }
                                </>
                            }
                             { /* Checkbox */                            
                                formGroup.fieldType === 'checkbox' && formGroup.hideField !== true && formGroup.checked === false &&
                                <>
                                    <Form.Check id={formGroup.id} style={{fontWeight: 'bold'}} type='checkbox' checked={false} onChange={formGroup.onChange}  label={formGroup.label} />
                                    {
                                        formGroup.error && formGroup.error != '' && 
                                        <div style={{color: "red", fontWeight: "bold"}}>&nbsp;&nbsp;&nbsp;&nbsp;{formGroup.error}</div>
                                    }
                                </>
                            }
                            { /* Checkbox */                            
                                formGroup.fieldType === 'blankrow' && formGroup.hideField !== true &&
                                <>&nbsp;</>
                            }



                            <Form.Text className="text-muted">
                                {formGroup.hint}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                );
            })
        );
    }

    render() {
        let fieldRow = <></>
        // Display the row only if hideField is not true.
        if (this.props.formGroupRow.formGroups[0].hideRow !== true) {
            fieldRow = <><Form.Row> {this.generateRow(this.props.formGroupRow)}</Form.Row></>
        }
        return (fieldRow);
    }
}