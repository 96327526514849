import React from 'react';
import TextFilter from './TextFilter';
import SelectFilter from './SelectFilter';
import { Button, Container, Row, Col } from 'react-bootstrap'
import { minusIcon, filterIcon } from "../../../img/font_awesome/font_awesome"

export default class PageBanner extends React.Component {
   render() {

      let textFilterComponent = '';
      if (this.props.selectFilterProps) {
         textFilterComponent =  <span style={{ display:'inline-block', marginLeft: 20 }}><TextFilter textFilterProps={this.props.textFilterProps}></TextFilter></span>
      }

      let selectFilterComponent = '';
      if (this.props.selectFilterProps) {
         selectFilterComponent = <span style={{ display:'inline-block', marginLeft: 20 }}><SelectFilter selectFilterProps={this.props.selectFilterProps}></SelectFilter></span>
      }

      let finderFilterComponent = '';
      if (this.props.finderFilterProps && this.props.finderFilterProps.id > 0) {
         finderFilterComponent =<><span> <Button variant="secondary" onClick={this.props.finderFilterProps.finderFilterReset}>{minusIcon} Account: {this.props.finderFilterProps.name}</Button></span></>   
      } else if (this.props.finderFilterProps) {
         finderFilterComponent = <span style={{ display:'inline-block', marginLeft: 20 }}>
            <Container>
               <Row>
                  <Col><Button variant='primary' onClick={this.props.finderFilterProps.finderFilterClick}>Account {filterIcon}</Button></Col>
               </Row>
            </Container>
         </span> 
      }



      let criteriaList = '';
      if (this.props.searchCriteria) {
         criteriaList = this.props.searchCriteria.map(criteria => {
            return <ul style={{ display: 'inline-block', marginBottom: 0, listStyleType: 'none', paddingLeft:10, fontSize: 14 }}><li style={{ textDecoration: "underline" }} >{ criteria.description}</li><li style={{ textAlign: "center" }}>{criteria.value}</li></ul>
         });
      }

      let buttonList = '';
      if (this.props.buttons) {
         buttonList = this.props.buttons.map((button, description) => {
            let buttonClassName = button.className;
            if (!buttonClassName) {
               buttonClassName = "btn btn-primary";
            }
            return (<><button className={buttonClassName} onClick={button.onClickAction} >{button.icon} {button.label}</button>&nbsp;</>)
         })
      }

      return (
         <h5 style={{ color: '#3674AB', paddingTop: 10, paddingBottom: 10, paddingLeft: 10, marginTop: 5, marginLeft:4, marginRight:4, border: '3px solid #3674AB', borderRadius: '5px' }}>
            {this.props.pageHeading}
            {textFilterComponent}
            {selectFilterComponent}
            {finderFilterComponent}
            <span style={{ marginRight: 20 }}>{criteriaList}</span>
            <span style={{ float: 'right', marginRight: 10 }}>{buttonList}</span>
         </h5>
      )
   }

}