import React, { Component } from 'react';
import { Modal, Button, Row, Col, Container } from 'react-bootstrap'

class EfileConfirmation extends Component {

   handleYesClick = (event) => {
      if (event) {
         event.preventDefault();
      }
      this.props.cancelConfirmation();
      this.props.proceedConfirmation();
   }

   handleNoClick = (event) => {
      if (event) {
         event.preventDefault();
      }
      this.props.cancelConfirmation();
   }

   render() {
      const hasDocumentCharge = this.props.firstPageCharge && this.props.firstPageCharge !== '' ? true : false;
      const hasAdditionalPagesCharge = this.props.additionalPagesCharge && this.props.additionalPagesCharge !== '' ? true : false;
      const hasSurcharge = this.props.surcharge && this.props.surcharge !== '' ? true : false;
      const hasTotalCharge = this.props.totalCharge && this.props.totalCharge !== '' ? true : false;
    


      let documentChargeRow = <></>
      if (hasDocumentCharge) {
         documentChargeRow = <Row><Col lg={1}></Col><Col lg={5}>Document charge:</Col><Col lg={5} style={{textAlign: 'right'}}>${this.props.firstPageCharge}</Col><Col lg={1}></Col></Row>
      }

      let additionalPagesChargeRow = <></>
      if (hasAdditionalPagesCharge) {
         additionalPagesChargeRow = <Row><Col lg={1}></Col><Col lg={5}>Additional pages:</Col><Col lg={5} style={{textAlign: 'right'}}>${this.props.additionalPagesCharge}</Col><Col lg={1}></Col></Row>
      }

      let surchargeRow = <></>
      if (hasSurcharge) {
         surchargeRow = <Row><Col lg={1}></Col><Col lg={5}>Credit card surcharge:</Col><Col lg={5} style={{textAlign: 'end'}}>${this.props.surcharge}</Col><Col lg={1}></Col></Row>
      }

      let totalChargeRow = <></>
      if (hasTotalCharge) {
         totalChargeRow = <Row style={{fontWeight: 'bold'}}><Col lg={1}></Col><Col lg={5}>Total to be charged:</Col><Col lg={5} style={{textAlign: 'right'}}>${this.props.totalCharge}</Col><Col lg={1}></Col></Row>
      }

      return (
         <Modal show={this.props.show} onHide={this.handleNoClick} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton >
               <Modal.Title style={{color: '#3674AB'}}>Continue to Payment?</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{borderTop: '2px solid black', borderBottom: '2px solid black', background: '#3674AB', color: 'white',  fontSize: 20}}>
            <Container fluid="true" className='h-100'>
               <Row><Col lg={1}></Col><Col lg={5}>Number of pages in uploaded file:</Col><Col lg={5} style={{textAlign: 'right'}}>{this.props.numberOfPages}</Col><Col lg={1}></Col></Row>
               <Row>&nbsp;</Row>
               {documentChargeRow}
               {additionalPagesChargeRow}
               {surchargeRow}
               <Row ><Col lg={1}></Col><Col lg={10} style={{textAlign: 'right'}}><hr style={{borderColor: 'white'}}/></Col><Col lg={1}></Col></Row>
               {totalChargeRow}
               <Row ><Col lg={1}></Col><Col lg={10} style={{textAlign: 'right'}}><hr style={{borderColor: 'white'}}/></Col><Col lg={1}></Col></Row>
              </Container>
              
            </Modal.Body>
            <Modal.Footer>
               <Button variant="primary" onClick={this.handleYesClick}>Yes</Button>
               <Button variant="secondary" onClick={this.handleNoClick}>No</Button>
            </Modal.Footer>
         </Modal>
      )
   }

}

export default EfileConfirmation;