import React, { Component } from 'react';
import { connect } from "react-redux";
import FormTemplate from "../../common/form/FormTemplate";
import { loadDocuments, setSelectedSearchType, SearchByNameKey } from "../../../redux/actions/searchActions";
import { validateDates } from "../../../utils/DateValidation";
import { LOADING_STATUES } from "../../constants/constants";
import translations from "../../../config/translation/search_by_name_translations.json";
import moment from 'moment';
import axios from "axios";
import configData from "../../../config/config.json";
import { handleError } from "../../../redux/actions/commonStuff";
import AjaxResult from "../../../utils/AjaxResult";
import NotableDates from '../../home/NotableDates';

const componentName = SearchByNameKey;

const matchDispatchToProps = (dispatch) => {
  return {
    setSelectedSearchType: () => dispatch(setSelectedSearchType(componentName)),
    searchByName: (searchCriteria, history, callback) => dispatch(loadDocuments(searchCriteria, history, callback, 'search/searchByName')),
    resetSearchCriteria: () => dispatch({ type: 'RESET_SEARCH_CRITERIA', componentName }),
    resetSearchSequence: () => dispatch({ type: 'RESET_SEARCH_SEQUENCE'}),
    resetSearchSequenceDocumentNumber: () => dispatch({ type: 'SEARACH_SEARCH_SEQUENCE_DOCUMENT'}),
    resetSearchDetail: () => dispatch({ type: 'RESET_SEARCH_DETAILS'})
  }
}

const mapStateToProps = (state, ownProps) => {
  let previousSearchKeyValue = {};
  if (ownProps.match.params.searchHistoryNumber) {
    let searchHistoryIndex = ownProps.match.params.searchHistoryNumber - 1;
    if (searchHistoryIndex >= 0) {
      let searchHistory = state.searchHistoryReducer.searchHistoryList[searchHistoryIndex];
      if (searchHistory) {
        searchHistory.displayCriteria.forEach((criteria) => {
          previousSearchKeyValue[criteria.key] = criteria.value;
        })
      }
    }
  } else {
    let searchCriteria = state.searchCriteriaReducer[componentName];
    if (searchCriteria) {
      searchCriteria.forEach((criteria) => {
        previousSearchKeyValue[criteria.key] = criteria.value;
      })
    }
  }

  let fromRecordedDate = previousSearchKeyValue.fromRecordedDate;
  if (!fromRecordedDate && state.countyConfigNotableDatesReducer.showNotableDates === true) {
    fromRecordedDate = state.landlinkConfigReducer.abstractIndexBeginDate;
  } 
  
  if (!fromRecordedDate) {
    fromRecordedDate = moment('01/01/1900').format('L');
  }

  let toRecordedDate = previousSearchKeyValue.toRecordedDate;
  if (!toRecordedDate && state.countyConfigNotableDatesReducer.useVerificationDate === true) {
    toRecordedDate = state.landlinkConfigReducer.abstractVerifiedDate;
  }
  
  if (!toRecordedDate) {
    toRecordedDate = moment().format('L');
  }

  return {
    notableDates: state.notableDatesReducer,
    showNotableDates: state.countyConfigNotableDatesReducer.showNotableDates,
    useVerificationDate: state.countyConfigNotableDatesReducer.useVerificationDate,
    abstractIndexBeginDate: state.landlinkConfigReducer.abstractIndexBeginDate,
    abstractVerifiedDate: state.landlinkConfigReducer.abstractVerifiedDate,
    documentTypes: state.documentTypesReducer.codeDescriptionResultList,
    isPropertyCode: state.landlinkConfigReducer.propertyCode,
    allOnlySearch: state.countyConfigSearch2Reducer.allOnlySearch,
    documentType: previousSearchKeyValue.documentType ? previousSearchKeyValue.documentType : 'ALL',
    creditCardUser: state.currentUserReducer.paymentMethod === 'CREDIT_CARD' ? true : false,
    instrumentGroups: state.instrumentGroupsReducer.codeDescriptionResultList,
    fromRecordedDate,
    toRecordedDate,
    instrumentGroup: previousSearchKeyValue.instrumentGroup ? previousSearchKeyValue.instrumentGroup : "ALL",
    grantorGranteeSearchIn: previousSearchKeyValue.grantorGranteeSearchIn ? previousSearchKeyValue.grantorGranteeSearchIn : "BOTH",
    grantorGranteeLastName1: previousSearchKeyValue.grantorGranteeLastName1 ? previousSearchKeyValue.grantorGranteeLastName1 : null,
    grantorGranteeMiddleInitial1: previousSearchKeyValue.grantorGranteeMiddleInitial1 ? previousSearchKeyValue.grantorGranteeMiddleInitial1 : null,
    grantorGranteeFirstName1: previousSearchKeyValue.grantorGranteeFirstName1 ? previousSearchKeyValue.grantorGranteeFirstName1 : null,
    andOr: previousSearchKeyValue.andOr ? previousSearchKeyValue.andOr : "OR",
    grantorGranteeLastName2: previousSearchKeyValue.grantorGranteeLastName2 ? previousSearchKeyValue.grantorGranteeLastName2 : null,
    grantorGranteeMiddleInitial2: previousSearchKeyValue.grantorGranteeMiddleInitial2 ? previousSearchKeyValue.grantorGranteeMiddleInitial2 : null,
    grantorGranteeFirstName2: previousSearchKeyValue.grantorGranteeFirstName2 ? previousSearchKeyValue.grantorGranteeFirstName2 : null,
    language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH'
  };
}

class SearchByName extends Component {

  state = {
    fromRecordedDate: this.props.fromRecordedDate,
    toRecordedDate: this.props.toRecordedDate,
    documentType: this.props.documentType,
    instrumentGroup: this.props.instrumentGroup,
    grantorGranteeSearchIn: this.props.grantorGranteeSearchIn,
    grantorGranteeLastName1: this.props.grantorGranteeLastName1,
    grantorGranteeFirstName1: this.props.grantorGranteeFirstName1,
    grantorGranteeMiddleInitial1: this.props.grantorGranteeMiddleInitial1,
    andOr: this.props.andOr,
    grantorGranteeLastName2: this.props.grantorGranteeLastName2,
    grantorGranteeFirstName2: this.props.grantorGranteeFirstName2,
    grantorGranteeMiddleInitial2: this.props.grantorGranteeMiddleInitial2,
    submitMessage: '',
    loadingStatus: "",

    token: '',
    creditCardPriceListWindowShow: false,
    creditCardAuthorizeDotNetWindowShow: false,
    creditCardPriceListReAuthWindowShow: false,

    errors: {}
  }

  // Same code used in all searches - BEGIN

  componentDidMount() {
    this.props.setSelectedSearchType();
    if (this.props.creditCardUser) {
      axios.post(configData.LANDSHARK_SERVER_URL + "creditcard/authorize", {}, { withCredentials: true })
        .then((response) => {

          let result = new AjaxResult(response);

          if (!result.isOk()) {
            result.handleError();
          } else {
            this.setState({ token: result.content().token });
          }
        }, (error) => {
          handleError(error, this.props.history);
        })
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
      submitMessage: '',
      errors: {}
    })
  }

  showLoading = () => {
    if (this.state.loadingStatus === "") {
      this.setState({
        loadingStatus: LOADING_STATUES.LOADING
      })
      return true;
    }
    return false;
  }

  hideLoading = (message, status, content) => {
    if (content.paymentNeededAgain === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListReAuthWindowShow: true
      })
    } else if (content.paymentNeeded === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: true
      })
    } else if (status === 'ERROR') {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    } else {
      this.setState({
        loadingStatus: "",
        submitMessage: message,
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    }
  }

  handleCreditCardAuthorizeDotNetWindowClose = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: false
      });
  }

  handleCreditCardPriceListClose = () => {
    this.setState(
      {
        creditCardPriceListWindowShow: false
      });
  }

  handleCreditCardPriceListContinue = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuth = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuthWindowClose = () => {
    this.setState(
      {
        creditCardPriceListReAuthWindowShow: false
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      if (this.showLoading() === true) {
        this.handleAuthorizedSubmit();
      }
    }
  }

  // Same code used in all searches - END

  handleAuthorizedSubmit = () => {
    this.props.resetSearchSequence();
    this.props.resetSearchSequenceDocumentNumber();
    this.props.resetSearchDetail();
    const { loadingStatus, submitMessage, errors, token, creditCardPriceListWindowShow, creditCardAuthorizeDotNetWindowShow, creditCardPriceListReAuthWindowShow, ...request } = this.state; // remove UI only fields before submitting
    this.props.searchByName(request, this.props.history, this.hideLoading);
  }

  handleValidation() {
    let errors = {};

    if (!this.state.grantorGranteeLastName1) {
      errors["grantorGranteeLastName1"] = translations[this.props.language].required;
      this.setState({ errors: errors });
      return false;
    }

    if (!this.state.grantorGranteeFirstName1 && this.state.grantorGranteeMiddleInitial1) {
      errors["grantorGranteeFirstName1"] = translations[this.props.language].required_if_mi;
      this.setState({ errors: errors });
      return false;
    }

    if (!this.state.grantorGranteeLastName2 && this.state.grantorGranteeFirstName2) {
      errors["grantorGranteeLastName2"] = translations[this.props.language].required_if_first_name;
      this.setState({ errors: errors });
      return false;
    }

    if (!this.state.grantorGranteeLastName2 && this.state.grantorGranteeMiddleInitial2) {
      errors["grantorGranteeLastName2"] = translations[this.props.language].required_if_mi;
      this.setState({ errors: errors });
      return false;
    }

    if (!this.state.grantorGranteeFirstName2 && this.state.grantorGranteeMiddleInitial2) {
      errors["grantorGranteeFirstName2"] = translations[this.props.language].required_if_mi;
      this.setState({ errors: errors });
      return false;
    }

    let formIsValid = validateDates(errors, this.state.fromRecordedDate, 'fromRecordedDate', this.state.toRecordedDate, 'toRecordedDate');
    if (!formIsValid) {
      this.setState({ errors: errors });
      return false;
    }

    this.setState({ errors: errors });
    return true;
  }

  handleClear = (event) => {
    event.preventDefault();
    this.props.resetSearchCriteria();

    let fromRecordedDate = moment('01/01/1900').format('L');
    if (this.props.showNotableDates === true) {
      fromRecordedDate = this.props.abstractIndexBeginDate;
    } 
  
    let toRecordedDate = moment().format('L');
    if (this.props.useVerificationDate === true) {
      toRecordedDate = this.props.abstractVerifiedDate;
    }

    this.setState({
      fromRecordedDate,
      toRecordedDate,

      documentType: 'ALL',
      instrumentGroup: 'ALL',

      grantorGranteeSearchIn: 'BOTH',

      grantorGranteeLastName1: '',
      grantorGranteeFirstName1: '',
      grantorGranteeMiddleInitial1: '',

      andOr: 'OR',
      grantorGranteeLastName2: '',
      grantorGranteeFirstName2: '',
      grantorGranteeMiddleInitial2: '',

      submitMessage: '',
      errors: {}
    });
  }

  handleDatePickerChangeFromRecordedDate = (date) => {
    this.setState({
      fromRecordedDate: moment(date).format('L')
    })
  }

  handleDatePickerChangeToRecordedDate = (date) => {
    this.setState({
      toRecordedDate: moment(date).format('L')
    })
  }

  render() {

    let formGroupRows = [
      {
        formGroups: [
          {
            id: 'grantorGranteeLastName1',
            label: translations[this.props.language].business_or_last_name,
            fieldType: 'text',
            required: true,
            primaryCriteria: true,
            value: this.state.grantorGranteeLastName1
          },
          {
            id: 'grantorGranteeFirstName1',
            label: translations[this.props.language].first_name,
            fieldType: 'text',
            placeholder: '(Optional)',
            value: this.state.grantorGranteeFirstName1,
            error: this.state.errors['grantorGranteeFirstName1']
          },
          {
            id: 'grantorGranteeMiddleInitial1',
            label: translations[this.props.language].middle_initial,
            fieldType: 'text',
            placeholder: '(Optional)',
            maxLength: 1,
            value: this.state.grantorGranteeMiddleInitial1,
            error: this.state.errors['grantorGranteeMiddleInitial1']
          },
          {
            id: 'andOr',
            label: translations[this.props.language].and_or,
            fieldType: 'select',
            options: [{ code: 'AND', description: translations[this.props.language].and }, { code: 'OR', description: translations[this.props.language].or }],
            value: this.state.andOr
          }
        ]
      },
      {
        formGroups: [{ id: 'grantorGranteeLastName2', label: translations[this.props.language].business_or_last_name, fieldType: 'text', value: this.state.grantorGranteeLastName2, error: this.state.errors['grantorGranteeLastName2'] },
        { id: 'grantorGranteeFirstName2', label: translations[this.props.language].first_name, fieldType: 'text', placeholder: '(Optional)', value: this.state.grantorGranteeFirstName2, error: this.state.errors['grantorGranteeFirstName2'] },
        { id: 'grantorGranteeMiddleInitial2', label: translations[this.props.language].middle_initial, fieldType: 'text', maxLength: 1, placeholder: '(Optional)', value: this.state.grantorGranteeMiddleInitial2, error: this.state.errors['grantorGranteeMiddleInitial2'] }, { fieldType: 'spaceHolder' }]
      },
      {
        formGroups: [
          {
            id: 'grantorGranteeSearchIn',
            label: translations[this.props.language].search_in,
            fieldType: 'select',
            options: [{ code: 'BOTH', description: translations[this.props.language].both_grantors_grantees }, { code: 'GRANTORS', description: translations[this.props.language].grantors_only }, { code: 'GRANTEES', description: translations[this.props.language].grantees_only }],
            value: this.state.grantorGranteeSearchIn
          }
        ]
      }
    ]

    if (this.props.isPropertyCode === true && this.props.allOnlySearch !== true && this.props.allOnlySearch !== 'true') {
      formGroupRows.push({
        formGroups: [{ id: 'documentType', label: translations[this.props.language].document_type, fieldType: 'select', value: this.state.documentType, options: this.props.documentTypes }]
      });
    }

    formGroupRows.push({
      formGroups: [{ id: 'instrumentGroup', label: translations[this.props.language].instrument_group, fieldType: 'select', options: this.props.instrumentGroups, value: this.state.instrumentGroup }]
    });

    formGroupRows.push({
      formGroups: [{ id: 'fromRecordedDate', label: translations[this.props.language].from_date, fieldType: 'date', value: this.state.fromRecordedDate, handleDatePickerChange: this.handleDatePickerChangeFromRecordedDate, error: this.state.errors['fromRecordedDate'] }]
    });

    formGroupRows.push({
      formGroups: [{ id: 'toRecordedDate', label: translations[this.props.language].to_date, fieldType: 'date', value: this.state.toRecordedDate, handleDatePickerChange: this.handleDatePickerChangeToRecordedDate, error: this.state.errors['toRecordedDate'] }]
    });

    const formProps = {
      componentName: componentName,
      pageHeading: translations[this.props.language].search_by_name,
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      onClear: this.handleClear,
      successIndicator: this.state.loadingStatus === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.loadingStatus === LOADING_STATUES.LOADING,
      submitMessage: this.state.submitMessage,
      primaryButtonLabel: translations[this.props.language].submit,
      clearButtonLabel: translations[this.props.language].clear,
      formGroupRows: formGroupRows,
      formRightArea: <NotableDates notableDates={this.props.notableDates} cardHeaderClassName='card-header-gray' />,

      handleAuthorizedSubmit: this.handleAuthorizedSubmit,

      // price list window
      creditCardPriceListWindowShow: this.state.creditCardPriceListWindowShow,
      handleCreditCardPriceListClose: this.handleCreditCardPriceListClose,
      handleCreditCardPriceListContinue: this.handleCreditCardPriceListContinue,

      // authoriz.net iframe window
      creditCardAuthorizeDotNetWindowShow: this.state.creditCardAuthorizeDotNetWindowShow,
      handleCreditCardAuthorizeDotNetWindowClose: this.handleCreditCardAuthorizeDotNetWindowClose,
      authorizeDotNetToken: this.state.token,

      // re-preauth price list window
      creditCardPriceListReAuthWindowShow: this.state.creditCardPriceListReAuthWindowShow,
      handleCreditCardPriceListReAuth: this.handleCreditCardPriceListReAuth,
      handleCreditCardPriceListReAuthWindowClose: this.handleCreditCardPriceListReAuthWindowClose
    }

    return <FormTemplate {...formProps} />
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(SearchByName);