import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import FormTemplate from "../common/form/FormTemplate";
import { reportAccount, exportAccount, setSelectedReportType, ReportAccountAuditKey } from "../../redux/actions/reportActions";
import { amPmOptions, hourOptions, minuteOptions, validateDateTimes, validateYearRangeLimit } from "../../utils/DateValidation";
import { LOADING_STATUES } from "../../components/constants/constants";
import AccountSelectionList   from "../common/selection/AccountSelectionList";

const componentName = ReportAccountAuditKey;

const matchDispatchToProps = (dispatch) => {
  return {
    setSelectedReportType: () => dispatch(setSelectedReportType(componentName)),
    reportAccount: (searchCriteria, history, callback) => dispatch(reportAccount(searchCriteria, history, callback)),
    exportAccount: (searchCriteria, history, callback) => dispatch(exportAccount(searchCriteria, history, callback))
  }
}

const mapStateToProps = (state) => {
  let reportByCriteria = state.reportCriteriaReducer[componentName];
 
  let fromDate = reportByCriteria['fromDate'] ? reportByCriteria['fromDate'] : moment().format('L');
  let toDate = reportByCriteria['toDate'] ? reportByCriteria['toDate'] : moment().format('L');
  let fromHour = reportByCriteria['fromHour'] ? reportByCriteria['fromHour'] : '12';
  let fromMinute = reportByCriteria['fromMinute'] ? reportByCriteria['fromMinute'] : '0';
  let fromAmPm = reportByCriteria['fromAmPm'] ? reportByCriteria['fromAmPm'] : 'am';
  let toHour = reportByCriteria['toHour'] ? reportByCriteria['toHour'] : '11';
  let toMinute = reportByCriteria['toMinute'] ? reportByCriteria['toMinute'] : '59';
  let toAmPm = reportByCriteria['toAmPm'] ? reportByCriteria['toAmPm'] : 'pm';
  let orderBy = reportByCriteria['orderBy'] ? reportByCriteria['orderBy'] : 'username';
  let dataSource = reportByCriteria['dataSource'] ? reportByCriteria['dataSource'] : 'FIVE';

  let accountName = reportByCriteria['accountName'] ? reportByCriteria['accountName'] : '';
  let statementNumber = reportByCriteria['statementNumber'] ? reportByCriteria['statementNumber'] : '';

  let transactionType = reportByCriteria['transactionType'] ? reportByCriteria['transactionType'] : 'all';
  let logInformation = reportByCriteria['logInformation'] ? reportByCriteria['logInformation'] : 'all';

  return {
    fromDate,
    fromHour,
    fromMinute,
    fromAmPm,
    toDate,
    toHour,
    toMinute,
    toAmPm,

    accountName,
    statementNumber,
    logTypes: state.auditLogTypesReducer.codeDescriptionResultList,
    logTypesAll: state.auditLogTypesAllReducer.codeDescriptionResultList,
    transactionTypes: state.auditTransactionTypesReducer.codeDescriptionResultList,
    transactionTypesAll: state.auditTransactionTypesAllReducer.codeDescriptionResultList,
    dataSourceTypes: state.dataSourceTypesReducer.codeDescriptionResultList,
    transactionType,
    logInformation,

    orderBy,
    dataSource,
    
    role: state.currentUserReducer.role
  };
}

class AccountReport extends Component {

  state = {
    fromDate: this.props.fromDate,
    toDate: this.props.toDate,
    fromHour: this.props.fromHour,
    fromMinute: this.props.fromMinute,
    fromAmPm: this.props.fromAmPm,
    toHour: this.props.toHour,
    toMinute: this.props.toMinute,
    toAmPm: this.props.toAmPm,

    accountName: this.props.accountName,
    statementNumber: this.props.statementNumber,
    transactionType: this.props.transactionType,
    logInformation: this.props.logInformation,
   
    orderBy: this.props.orderBy,

    dataSource: this.props.dataSource,
  
    showAccountList: false,
    
    loadingStatus: "",
    errors: {}
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      if (this.showLoading() === true) {
        const { loadingStatus, errors, ...request } = this.state; // remove UI only fields before submitting
        this.props.reportAccount(request, this.props.history, this.hideLoading );
      }
    }
  }

  handleExport = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      if (this.showLoading() === true) {
        this.props.exportAccount(this.state, this.props.history, this.hideLoading );
      }
    }
  }

  handleValidation() {
    let errors = {};
    if (!this.state.fromDate) {
      errors['fromDate'] = "Required.";
      this.setState({ errors: errors });
      return false;
    }

    let formIsValid = validateDateTimes(errors, this.state.fromDate, this.state.fromHour, this.state.fromMinute, this.state.fromAmPm, 'fromDate', this.state.toDate, this.state.toHour, this.state.toMinute, this.state.toAmPm, 'toDate');
    if (!formIsValid) {
      this.setState({ errors: errors });
      return false;
    }
    
    formIsValid = validateYearRangeLimit(errors, this.state.fromDate, 'fromDate', this.state.toDate, 'toDate');
    if (!formIsValid) {
      this.setState({ errors: errors });
      return false;
    }

    this.setState({ errors: errors });
    return true;
  }

  handleChange = (event) => {
    event.preventDefault();
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  handleDatePickerChangeFromDate = (date) => {
    this.setState({
      fromDate : moment(date).format('L')
    })
  }

  handleDatePickerChangeToDate = (date) => {
    this.setState({
      toDate : moment(date).format('L')
    })
  }

  showLoading = () => {
    if (this.state.loadingStatus === "") {
      this.setState({
        loadingStatus: LOADING_STATUES.LOADING
      })
      return true;
    } 
    return false;
  }

  hideLoading = (message, status) => {
    if (status === 'ERROR') {
      alert(message);
    }
    this.setState({
      loadingStatus: ""
    })
  }

  handleAccountLookup = () => {
    this.setState( {
      showAccountList : true
    });
  }

  handleCloseAccountLookup = (selectedId, selectedName) => {
    this.setState({
      showAccountList: false,
      accountId: selectedId,
      accountName: selectedName
    })
  }

  componentDidMount() {
    this.props.setSelectedReportType();
  }

  render() {

    let logTypes = this.props.logTypes;
    let transactionTypes = this.props.transactionTypes;
    if (this.props.role === 'TRIMIN' || this.props.role === 'COUNTY') {
      logTypes = this.props.logTypesAll;
      transactionTypes = this.props.transactionTypesAll;
    }

    let formGroupRows = [
      {
        formGroups: [{ id: 'fromDate', label: 'From Date', fieldType: 'date', value: this.state.fromDate, handleDatePickerChange: this.handleDatePickerChangeFromDate, error: this.state.errors['fromDate']  },
        { id: 'fromHour', label: '', fieldType: 'select', options: hourOptions, value: this.state.fromHour },
        { id: 'fromMinute', label: '',fieldType: 'select', options: minuteOptions, value: this.state.fromMinute },
        { id: 'fromAmPm', label: '', fieldType: 'select', options: amPmOptions, value: this.state.fromAmPm }]
      },
      {
        formGroups: [{ id: 'toDate', label: 'To Date', fieldType: 'date', value: this.state.toDate, handleDatePickerChange: this.handleDatePickerChangeToDate, error: this.state.errors['toDate']  },
        { id: 'toHour', label: '', fieldType: 'select', options: hourOptions, value: this.state.toHour },
        { id: 'toMinute', label: '', fieldType: 'select', options: minuteOptions, value: this.state.toMinute },
        { id: 'toAmPm', label: '', fieldType: 'select', options: amPmOptions, value: this.state.toAmPm }]
      }]

    if (this.props.role === 'TRIMIN' || this.props.role === 'COUNTY') {
      formGroupRows.push({
        formGroups: [{ id: 'accountName', label: 'Account Name', fieldType: 'text', value: this.state.accountName },
        { id: 'accountLookupButton', fieldType: 'lookup', onClick: this.handleAccountLookup }]
      });
    }
      
    formGroupRows.push({
        formGroups: [{ id: 'statementNumber', label: 'Statement Number', fieldType: 'text', value: this.state.statementNumber }]
    });
    formGroupRows.push({
      formGroups: [{ id: 'logInformation', label: 'Log Type', fieldType: 'select', options: logTypes, value: this.state.logInformation }]
    });
    formGroupRows.push({
        formGroups: [{ id: 'transactionType', label: 'Transaction Type', fieldType: 'select', options: transactionTypes, value: this.state.transactionType }]
    });

    formGroupRows.push({
        formGroups: [{ id: 'orderBy', label: 'Order By', fieldType: 'select', options: [{ code :'timestamp', description: 'Date and Time'}, {code: 'username', description: 'Username'}], value: this.state.orderBy }]
    });

    if (this.props.role === 'TRIMIN' || this.props.role === 'COUNTY') {
      formGroupRows.push({
        formGroups: [{ id: 'dataSource', label: 'Data Source', fieldType: 'select', options: this.props.dataSourceTypes, value: this.state.dataSource }]
      });
    }

    const formProps = {
      componentName: componentName,
      pageHeading: "Audit Report - Account",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      onSeconaryClick: this.handleExport,
      primaryButtonLabel: "PDF",
      secondaryButtonLabel: "Excel",
      successIndicator: this.state.loadingStatus === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.loadingStatus === LOADING_STATUES.LOADING,
      formGroupRows: formGroupRows
    }

    return <>
    <FormTemplate {...formProps} />
    <AccountSelectionList showModal={this.state.showAccountList} codeDescriptionResults={this.props.accounts} onHide={(selectedId, selectedName) => this.handleCloseAccountLookup(selectedId, selectedName)} />
    </>
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(AccountReport);