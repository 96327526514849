const initialState = {     
      includeInactive: false,
	filterText: '',
      accountId: '',
      accountName: ''
}

const userListFiltersReducer = (state = initialState, action) => {
      switch (action.type) {
            case 'SET_USER_LIST_FILTERS':
                  return {
                        ...state,
                        includeInactive: action.filters.includeInactive,
                        filterText: action.filters.filterText,
                        accountId: action.filters.accountId
                  }
            case 'SET_USER_LIST_ACCOUNT_FILTER':
                  return {
                        ...state,
                        accountId: action.accountFilter.accountId,
                        accountName: action.accountFilter.accountName
                  }
            case 'LOGOUT_USER':
                  return {
                        includeInactive: false,
                        filterText: ''
                  }
            default:
                  return state;
      }

};
export default userListFiltersReducer;