import React, { Component } from 'react';
import { connect } from "react-redux";
import { setSelectedControlPanelType, AccountsControlPanelKey, updateAccountListFilters } from "../../redux/actions/controlPanelActions";

import Alert from '../common/modal/Alert';
import { Container, Row, Col, Table, Button, Dropdown } from 'react-bootstrap'
import PageBanner from '../common/page/PageBanner'
import Paginator from '../common/page/Paginator'
import { getPaginatorHelper } from '../common/page/PaginatorHelper'
import Loading from '../common/modal/Loading';
import Confirmation from '../common/modal/Confirmation';

import configData from "../../config/config.json"
import axios from "axios";
import { saveAs } from 'file-saver';

import { LOADING_STATUES, ALERT_TYPES } from "../constants/constants";

const componentName = AccountsControlPanelKey;

const mapStateToProps = state => {
  return {
    sideNavigationOpen: state.sideNavigationReducer,
    paginationPage: state.accountsPaginationReducer,
    countyAdminReload: state.countyConfigFlatFeeOverrideUISettingsReducer.countyAdminReload,
    userId: state.currentUserReducer.id,
    accountListFilters: state.accountListFiltersReducer
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName)),
    updateAccountListFilters: (filters) => dispatch(updateAccountListFilters(filters)),
    userListAccountFilter: (accountId, accountName) => dispatch({ type: 'SET_USER_LIST_ACCOUNT_FILTER', accountFilter: {accountId, accountName} }),
    updateAccountList: (list) => dispatch({ type: 'LANDSHARK_ACCOUNTS', results: list }) 
  }
}

class Accounts extends Component {

  state = {
    filteredList: [],
    selectedId: 0,
    selectedAccountName: '',
    includeInactive: this.props.accountListFilters.includeInactive ? this.props.accountListFilters.includeInactive : false,
    filterText: this.props.accountListFilters.filterText ? this.props.accountListFilters.filterText : '',
    status: '',
    showDeleteConfirmationDialog: false,
    showChangeAccountStatusConfirmationDialog: false,
    activateOrDeactivate: '',
    showAlert: false,
    alertMessage: '',
    alertType: ALERT_TYPES.OK
  }

  showLoading = () => {
    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING
      })
      return true;
    }
    return false;
  }

  handleTextFilter = (filterText) => {
    this.setState({ filterText: filterText }, function () { this.handleFilter() })
  }

  handleSelectFilter = (filterSelected) => {
    this.setState({ includeInactive: filterSelected === 'include' ? true : false }, function () { this.handleFilter() })
  }

  handleRedirectToUsers = (event) => {
    event.preventDefault();
    let selectedAccountId = event.target.closest("tr").id;
    let selectedAccountName = event.target.closest("tr").getAttribute("name");

    this.setState({ selectedAccountId, selectedAccountName });

    this.props.history.push('/userManager');

    this.props.userListAccountFilter(selectedAccountId, selectedAccountName);
  }


  handleRowReload = (event) => {
    event.preventDefault();
    this.props.history.push('/controlPanel/accountReload/' + event.target.closest("tr").id + "/" + event.target.closest("tr").getAttribute("name"));
  }

  handleRowAccess = (event) => {
    event.preventDefault();
    this.props.history.push('/controlPanel/accountPageAccess/' + event.target.closest("tr").id + "/" + event.target.closest("tr").getAttribute("name"));
  }

  handleRowEdit = (event) => {
    event.preventDefault();
    this.props.history.push('/controlPanel/accounts/' + event.target.closest("tr").id);
  }

  // Delete methods

  handleDeleteAccount = () => {
    if (this.showLoading() === true) {
      axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/accountDelete", { accountId: this.state.selectedId, filterText: this.state.filterText, includeInactive: this.state.includeInactive }, { withCredentials: true })
        .then((response) => {
          this.setState({ filteredList: response.data.content.accounts, status: '' }, this.showAlert(response.data.message))
          this.props.updateAccountList(response.data.content.accountCodeDescriptionResults);
        }, (error) => {
          this.setState({ status: '' }, this.showWarning(error.response.data.message));
        });
      this.props.history.push('/controlPanel/accounts');
    }
  }

  showDeleteConfirmation = (event) => {
    let selectedId = event.target.closest("tr").id;
    let selectedAccountName = event.target.closest("tr").getAttribute("name");
    this.setState({
      selectedId,
      selectedAccountName,
      showDeleteConfirmationDialog: true
    })
  }

  hideDeleteConfirmation = () => {
    this.setState({
      showDeleteConfirmationDialog: false
    })
  }

  // Delete methods - end

  openNewAccountEntry = (event) => {
    event.preventDefault();
    this.props.history.push('/controlPanel/accounts/0');
  }

  exportData = (event) => {
    event.preventDefault();
    if (this.showLoading() !== true) {
      return;
    }

    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/accountExport", { filterText: this.state.filterText, includeInactive: this.state.includeInactive }, { responseType: 'arraybuffer', withCredentials: true })
      .then((response) => {
        let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'accounts.xlsx');
        this.setState({ status: '' })
      }, (error) => {
        console.log(error);
        this.setState({ status: '' }, this.showWarning(error.message));
      });
  }

  // Activate/Deactivate methods

  showChangeAccountStatusConfirmation = (event) => {
    let selectedId = event.target.closest("tr").id;
    let selectedAccountName = event.target.closest("tr").getAttribute('name');
    let activateOrDeactivate = event.target.id;
    this.setState({
      activateOrDeactivate,
      selectedId,
      selectedAccountName,
      showChangeAccountStatusConfirmationDialog: true
    })
  }

  hideChangeAccountStatusConfirmation = () => {
    this.setState({
      showChangeAccountStatusConfirmationDialog: false
    })
  }

  handleChangeAccountStatus = (event) => {

    if (this.showLoading() === true) {
      axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/accountChangeStatusList", { accountId: this.state.selectedId, filterText: this.state.filterText, includeInactive: this.state.includeInactive }, { withCredentials: true })
        .then((response) => {
          this.setState({ filteredList: response.data.content.accounts, status: '', activateOrDeactivate: '' }, this.showAlert(response.data.message))
          this.props.updateAccountList(response.data.content.accountCodeDescriptionResults);
          this.props.history.push('/controlPanel/accounts');
        }, (error) => {
          this.setState({ status: '', activateOrDeactivate: '' }, this.showWarning(error.message));
          this.props.history.push('/controlPanel/accounts');
        });
    }
  }

  // Activate/Deactivate methods - end

  componentDidMount() {
    this.props.setSelectedControlPanelType();
    this.handleFilter();
  }

  handleFilter = () => {
    if (this.showLoading() !== true) {
      return;
    }

    let filters = { filterText: this.state.filterText, includeInactive: this.state.includeInactive };
    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/accounts", filters, { withCredentials: true })
      .then((response) => {
        this.setState({ filteredList: response.data.content.accounts, status: '' })
        this.props.updateAccountListFilters(filters);
      }, (error) => {
        this.setState({ status: '' }, this.showAlert(error.message));
      });
  }

  showAlert = (alertMessage) => {
    this.setState({
      showAlert: true,
      alertMessage
    })
  }

  showWarning = (alertMessage) => {
    this.setState({
      alertType: ALERT_TYPES.WARNING,
      showAlert: true,
      alertMessage
    })
  }

  hideAlert = () => {
    this.setState({
      showAlert: false,
      alertMessage: '',
      alertType: ALERT_TYPES.OK
    })
  }

  render() {

    let formTemplateMarginLeft = 60
    if (this.props.sideNavigationOpen) {
      formTemplateMarginLeft = 270
    }

    let buttons = [{ label: 'New', onClickAction: this.openNewAccountEntry }, { label: 'Export', onClickAction: this.exportData, className: 'btn btn-secondary' }]

    const selectFilterProps = {
      selectFilter: this.handleSelectFilter,
      options: [{ code: "include", description: "All Accounts" }, { code: "exclude", description: "Active Accounts Only" }],
      selected: this.state.includeInactive ? "include" : "exclude"
    }

    const textFilterProps = {
      textFilter: this.handleTextFilter,
      filterText: this.state.filterText
    }

    const numberOfVisibleRecords = 10;
    let numberOfPages = Math.ceil(this.state.filteredList.length / numberOfVisibleRecords);
    let paginationHelper = getPaginatorHelper(this.props.paginationPage, this.state.filteredList, numberOfVisibleRecords);

    return (

      <div style={{ marginTop: 4, marginLeft: formTemplateMarginLeft, marginRight: 20, paddingBottom: 4, borderTop: '1px solid lightGray', boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)"  }}>
        <PageBanner pageHeading='Accounts' textFilterProps={textFilterProps} selectFilterProps={selectFilterProps} buttons={buttons} />
        <Container fluid="true">
          <Row>
            <Col style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0, marginTop: 20, marginLeft: 20, marginRight: 20, border: '3px solid darkGray', borderRadius: '5px' }}>
              <Table striped hover size="sm" >
                <thead>
                  <tr>
                    <th style={{ width: '30%' }} scope="col">Account</th>
                    <th style={{ width: '10%' }} scope="col">Type</th>
                    <th style={{ width: '15%' }} scope="col">Payment Method</th>
                    <th style={{ width: '15%' }} scope="col">Monthly Fee ($)</th>
                    <th style={{ width: '30%' }} scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    paginationHelper.currentPageRecordList.map((accountResult) => {
                      let inactiveBadge = accountResult.status === 'A' ? "" : "inactive";
                      return (
                        <tr style={{ cursor: "pointer" }} id={accountResult.id} name={accountResult.name} onClick={this.handleRowClick}>
                          <td>{accountResult.name}<span className='badge badge-light'>{inactiveBadge}</span></td>
                          <td>{accountResult.accountTypeDescription}</td>
                          <td>{accountResult.paymentMethodDescription}</td>
                          <td>{accountResult.monthlyFeeDescription}</td>
                          
                          <td style={{ float: 'right' }}>
                            <><Button variant="primary" size ='sm'  onClick={this.handleRowEdit}>Edit</Button></>
                           
                            <Dropdown className="d-inline mx-1" style={{margin:0}} >
                              <Dropdown.Toggle size ='sm'  id="dropdown-autoclose-true" variant='secondary' >More</Dropdown.Toggle>
                              <Dropdown.Menu style={{ border:'2px solid darkgray', padding: 2, minWidth:'8rem'}}>
                                <Dropdown.Item style={{ paddingLeft: 5, paddingRight: 5, margin:0}} href="#" onClick={this.handleRowAccess}>Access</Dropdown.Item>
                                {
                                  accountResult.accountType === 'FLAT_FEE' && this.props.countyAdminReload === true &&
                                  <Dropdown.Item style={{paddingLeft: 5, paddingRight: 5}} href="#" onClick={this.handleRowReload}>Reload</Dropdown.Item>
                                }
                                <Dropdown.Item style={{paddingLeft: 5, paddingRight: 5}} href="#" onClick={this.handleRedirectToUsers}>Users({accountResult.userCount})</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            {
                              accountResult.status === 'A' &&
                              <><Button id='Deactivate' variant="danger" size ='sm' onClick={this.showChangeAccountStatusConfirmation}>Deactivate</Button>&nbsp;</>
                            }
                            {
                              accountResult.status !== 'A' &&
                              <><Button id='Activate' variant="secondary" size ='sm'  onClick={this.showChangeAccountStatusConfirmation}>&nbsp;&nbsp;Activate&nbsp;&nbsp;</Button>&nbsp;</>
                            }
                            <Button variant="danger" size ='sm'  onClick={this.showDeleteConfirmation} >Delete</Button>

                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>&nbsp;</Row>
          <Row>
            <Col style={{marginLeft: 4}}>Displaying Results: {paginationHelper.currentPageStartingRecordNumber}-{paginationHelper.currentPageEndingRecordNumber} of {this.state.filteredList.length}</Col>
            <Col><Paginator style={{ justifyContent: 'center' }} componentName={componentName} numberOfVisibleRecords={numberOfVisibleRecords} numberOfPages={numberOfPages} ></Paginator></Col>
          </Row>

        </Container>
        <Loading loadingIndicator={this.state.status === LOADING_STATUES.LOADING} />
        <Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} type={this.state.alertType} />
        <Confirmation show={this.state.showDeleteConfirmationDialog} cancelConfirmation={this.hideDeleteConfirmation} proceedConfirmation={this.handleDeleteAccount} question={'Delete account ' + this.state.selectedAccountName + ' and users?'} />
        <Confirmation show={this.state.showChangeAccountStatusConfirmationDialog} cancelConfirmation={this.hideChangeAccountStatusConfirmation} proceedConfirmation={this.handleChangeAccountStatus} question={this.state.activateOrDeactivate + ' Account ' + this.state.selectedAccountName + " and users?"} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);