import React, {Component} from 'react';
import { connect } from "react-redux";
import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, UserPurgeScheduleControlPanelKey } from "../../redux/actions/controlPanelActions";
import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import { LOADING_STATUES } from "../../components/constants/constants";

const componentName = UserPurgeScheduleControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

class UserPurgeSchedule extends Component {

  state = {
    automaticPurge: false,
    automaticPurgeAfterDays: "0",
    status: ""
  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING
      })
    } else {
      return;
    }

    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/userPurgeSchedule", this.state, { withCredentials: true })
    .then(
      (response) => {
        this.setState({ 
          status: LOADING_STATUES.SUCCESS
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  handleChange = (event) => {
    let value = event.target.value;
    value = value === "true" || value === "false" ? JSON.parse(event.target.value) : value;

    this.setState({
      status: "",
      [event.target.id]: value ? value : (event.target.type === 'number' ? 0 : value)
    })
  }

	componentDidMount() {
    this.props.setSelectedControlPanelType();

    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/userPurgeSchedule", { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState( {
          automaticPurge: content.automaticPurge,
          automaticPurgeAfterDays: content.automaticPurgeAfterDays,
          status : ""
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }
  
  render() {
    const formProps = {
      componentName: componentName,
      pageHeading: "User Admin - Purge Schedule",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      successIndicator: this.state.status === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.status === LOADING_STATUES.LOADING,
      formGroupRows: [
        {
          formGroups: [{ id: 'automaticPurge', label: 'Automatic Purge Credit Card Users', fieldType: 'select', value: this.state.automaticPurge, style: {maxWidth: 200} }]
        },
        {
          formGroups: [{ id: 'automaticPurgeAfterDays', label: 'After Inactive for (days)', fieldType: 'number', disableField: this.state.automaticPurge === false, value: this.state.automaticPurgeAfterDays, style: {maxWidth: 200}  }]
        }
      ]
    }

    return <FormTemplate {...formProps} />
  }
}

export default connect(null, matchDispatchToProps)(UserPurgeSchedule);